import React, {useState} from 'react';
import { Banner, Button, LegacyStack, Text } from "@shopify/polaris";
import {LINKS} from '../../../../../constants/constants';
import styled from 'styled-components'
const DiscountFuncWarningBannerWrapper = styled.div`
  .WarningBanner__wrapper {
      margin-top: 10px;
      color: #E49113;
  }

  .WarningBanner__button .Polaris-Button {
      background-color: unset;
      border-color: #E1B878;
      color: inherit;
  }

  .WarningBanner__link {
      text-decoration: none;
      color: inherit;
  }

  .WarningBanner__decoratedLink {
      color: inherit;
  }
`
const DiscountFuncWarningBanner = ({handleDiscountChange}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <DiscountFuncWarningBannerWrapper>
      {
        isOpen &&
          <div className='WarningBanner__wrapper'>
            <Banner status='warning' onDismiss={() => setIsOpen(false)}>
              <LegacyStack vertical>
                <Text variant="bodyMd" as="span">You can only add 1 discount per checkout with Shopify Functions.<a
                    href={LINKS.shopifyFunctionsHelp}
                    target="_blank"
                    rel='noopener noreferrer'
                    className='WarningBanner__decoratedLink'
                  >
                    Learn more
                  </a>
                </Text>
                <div className='WarningBanner__button'>
                  <Button onClick={() => handleDiscountChange('script')}>
                    I want to add multiple discounted upsells
                  </Button>
                </div>
              </LegacyStack>
            </Banner>
          </div>
      }
    </DiscountFuncWarningBannerWrapper>
  );
};

export default DiscountFuncWarningBanner;